.text-center {
    text-align: center;
}

.m-auto {
    margin: 0 auto;
}

// line clamps Code Start
.line-clamp-1 {
    @include clamp;
    -webkit-line-clamp: 1;
}

.line-clamp-2 {
    @include clamp;
    -webkit-line-clamp: 2;
}

.line-clamp-3 {
    @include clamp;
    -webkit-line-clamp: 3;
}

.line-clamp-4 {
    @include clamp;
    -webkit-line-clamp: 4;
}
// line clamps Code End