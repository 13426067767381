body {
  font-family: $basefont;
  font-weight: 400;
  color: $paracol;
  line-height: 1.625em;
  overflow: hidden;
}

.header {
  .brand-logo {
    &__anchor {
      svg {
        width: 12.75em;
        height: 7em;

        @media #{$mob} {
          height: auto;
        }
        path {
          fill: $whitecol;

          @media #{$mob} {
            fill: $whitecol;
          }
        }
      }
    }
  }
}


.loadjs {
  overflow: hidden;
}

.img-wrapper {
  position: relative;
}

.container-fluid {
  max-width: 100%;
  padding: 0;

  @media #{$ipad} {
    padding: 0 5em;
  }

  @media #{$ipad} {
    padding: 0 3em;
  }

  @media #{$mob} {
    padding: 0 2em;
  }
}

.container-s {
  max-width: 100%;
  padding: 0 3.385%;

  @media #{$ipad} {
    padding: 0 3em;
  }

  @media #{$mob} {
    padding: 0 2em;
  }
}

.container {
  max-width: 100%;
  padding: 0 10.25%; // 195px

  @media #{$ipadpro} {
    padding: 0 5em;
  }

  @media #{$ipad} {
    padding: 0 3em;
  }

  @media #{$mob} {
    padding: 0 2em;
  }
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  padding: 0 0.9375em;
  z-index: 2;
}

.sec__wrapper {
  flex-direction: column;
}

.section {
  position: relative;
  padding: 2em 0;

  @media #{$tab} {
    padding: 3em 0;
  }

  @media #{$desktop} {
    padding: 5.625em 0;
  }
}

section {
  overflow: hidden;
}