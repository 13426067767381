.form-group {
    position: relative;

    @media #{$ipadpro} {}
}

.input-group {
    position: relative;
    padding-bottom: 1.5em;
    cursor: text;
    transition: all 0.22s ease-out;
    z-index: 1;

    &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 32px;
        background: #e3204a;
        transition: 0.4s cubic-bezier(0.93, 0.01, 0, 0.98);
    }

    &.focus {
        &:before {
            width: 100%;
        }
    }
}

label {
    font-size: 1em;
    font-weight: 400;
    font-family: $basefont;
    transform: translateY(0);
    transition: all 0.18s ease-out;
    line-height: 1;

    @media #{$ipadpro} {
        font-size: 1.25em;
    }

    @media #{$mob} {
        font-size: 1.125em;
    }
}

.form-control {
    width: 100%;
    background: 0 0;
    border: 0;
    left: 0;
    outline: 0;
    padding: 1.05em;
    margin: 0;
    color: $paracol;
    font-size: 1.25em;
    margin-bottom: 0;
    line-height: 1.28;
    border: 1px solid rgb(255, 255, 255, 0.2);
    border-radius: 4px;
    font-family: $basefont;

    &.error {
        color: #000;
        font-size: 1.25em;
        font-weight: initial;
    }



    @media #{$ipadpro} {
        font-size: 1.125em;

        &.error {
            font-size: 1.125em;
        }
    }

    @media #{$mob} {
        font-size: 0.75em;
        padding: 1em;

        &.error {
            font-size: 1em;
        }
    }

    // &:focus {
    //     color: $blackcol;
    //     border-color: #363636;
    //     outline: 0;
    //     box-shadow: none;
    // }
}



input::placeholder {
    color: $whitecol;
}

.select-arrow {
    position: relative;

    &::after {
        content: "";
        position: absolute;
        background: url(../svg/black-select-arrow.svg) no-repeat;
        background-size: contain;
        width: 0.4375em;
        height: 0.3125em;
        right: 0.875em;
        top: 45%;
    }
}

.contact-form {
    &__wrapper {
        .h4 {
            margin-bottom: 1.7142em;
        }

        .form-group {
            margin-bottom: 3.125em;

            &.phone-group {
                .iti--separate-dial-code {
                    width: 100%;
                    @media #{$mob} {
                        padding-bottom: 1em;
                        border-bottom: 1px solid $paracol;
                    }

                    input {
                        @media #{$mob} {
                            padding-top: 0;
                            padding-bottom: 0;
                            border-bottom: none;
                        }
                    }

                    .iti__flag-container {
                        @media #{$mob} {
                            bottom: 0.5em;
                            top: auto;
                        }
                    }

                    .iti__selected-flag {
                        background-color: transparent;
                        // padding-bottom: 1em;
                    }

                    .iti__selected-dial-code {
                        color: $paracol;
                    }

                }

                .iti__arrow {
                    border-top: 4px solid $paracol;
                    border-bottom: 0;
                }
            }
        }

        input {
            width: 100%;
            background: transparent;
            border: none;
            border-bottom: 1px solid $paracol;
            padding-bottom: 1em;
            color: $paracol;
            font-size: 1.25em;
            font-weight: 400;
            font-family: $basefont;
            border-radius: 0;

            &::placeholder {
                font-size: 1em;
                font-weight: 400;
                color: $paracol;
                text-transform: capitalize;
                font-family: $basefont;
            }
        }

        .phone-group {
            z-index: 3;
        }
    }

    // .iti--allow-dropdown {
    //     padding-left: 5.875em !important
    // }
}

.news-cards {
    .show-category {
        @media #{$mob} {
            margin-bottom: 2em;
        }

        .form-control {
            &.select {
                color: $paracol;
                background: $whitecol;
                border: 1px solid #111820;
                border-radius: 5px;
                width: 8.5em;
                padding: 0.6875em;
                position: relative;
                font-size: 1.125em;
                box-sizing: border-box;
                -webkit-appearance: none;
                cursor: pointer;
            }
        }

        .drop-downWrapper {
            display: none;
            border: 1px solid $blackcol;
            border-top: 0;

            .btn-select {
                border-bottom: 1px solid #CCCCCC;
                cursor: pointer;

                input {
                    padding: 0.75em 0.875em;
                    background: $whitecol;
                    border: 0;
                    font-size: 1em;
                    color: $paracol;
                    cursor: pointer;

                    @media #{$ipadpro} {
                        font-size: 0.85em;
                    }
                }

            }

            label {
                font-size: 1em;
                color: $paracol;
                cursor: pointer;
            }

            .radio-input {
                margin: 0.75em 0 0.75em 0.9375em;
                cursor: pointer;
            }

            .apply-btn {
                input {
                    color: $secondarycol;
                    text-decoration: underline;
                    text-decoration-color: $secondarycol;
                }
            }
        }

        &.show {
            .drop-downWrapper {
                display: block;
                position: absolute;
                z-index: 2;
                background: #fff;
                width: 100%;
            }

            .select {
                border-bottom: 0;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }

    .form-group {
        &.radio-select {
            display: flex;
            align-items: center;

            label {
                margin-left: 0.5em;
            }
        }
    }
}



// --------------------  enquire----------------------- //

.enquire {
    &.fancybox__content {
        width: 67.875em;
        // height: 46.875em;
        height: auto;
        @media #{$ipad} {
            width: auto;
        }
        @media #{$mob} {
            width: 95%;
        }
    }

    .form__wrapper {
        background: #fff;
        // padding: 2em 0 0 0;
        position: relative;
    }

    .form-group {
        margin-bottom: 1em;
    }

    .form-control,
    textarea {
        border: 1px solid #CCCCCC;

        &::placeholder {
            color: $paracol;
        }
    }

    .msg-group {
        z-index: 0;
    }

    .btn-submit-wrapper {
        position: relative;
        display: inline-block;
    }

    .btn-loader {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
    }
}

.fancybox__content :focus:not(.carousel__button.is-close) {
    outline: none !important;
    box-shadow: none;
}

.footer {
    .form-control {
        color: $whitecol;
    }
}

.invalid-feedback {
    color: red;
}

.iti input,
.iti input[type=text] {
    @media (min-width:992px) {
        padding-left: 116px !important
    }
}

.footer__wrapper {
    .g-recaptcha {
        margin-bottom: 1.5em;
    }
    
}

.enquire {
    .input-group {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }

    .invalid-feedback {
        @media #{$mob} {
            font-size: .8em;
            line-height: normal;
            margin-top: 0;
            bottom: 0em!important;
            position: relative!important;
        }
        strong {
            @media #{$mob} {
                font-size: inherit;
                line-height: inherit;
            }
        }
    }
}

.input-group {
    &.phone-group {
        .invalid-feedback {
            position: absolute;
            bottom: -2em;
        }
    }


}

.invalid-feedback {
    position: absolute;
    bottom: -.5em;
}

.form-sec {
    .form-group {
        display: flex;
        flex-wrap: wrap;
    }
}

.form-sec {
    .invalid-feedback {
        // bottom: -2em;
        top: 100%;
        // @media #{$mob} {
        //     bottom: -2.5em;
        // }
    }
}

.phone-group {
    .iti {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        // flex-direction: column;
        @media #{$mob} {
            max-height: 3.303em;
        }
    }
}

.jeSnNy{
    display: none !important;
}

.iti--separate-dial-code .iti__flag-container {
    right: auto #{"/*rtl: 0*/"};
    left: 0 #{"/*rtl: auto*/"};
    @media #{$mob} {
        height: 2.64em;
    }
}