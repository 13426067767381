.navigation-wrapper {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  z-index: 12;

  @media #{$mob} {
    justify-content: space-between;
  }

  &.open {
    .menu__menuitem {
      &.cross {
        width: auto;
        height: auto;
        transition: .5s;
      }
    }
  }
}



.navigation {
  display: flex;
  justify-content: end;
  align-items: center;

  @media #{$ipadpro} {
    position: fixed;
    right: -100%;
    width: 100%;
    height: 100vh;
    top: 0;
    transition: 0.7s;
    background: $whitecol;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 7;
    overflow-y: scroll;
    padding: 0;

    &.open {
      right: 0;
      width: 350px;
      overflow: hidden;
      box-shadow: 0 3px 10px rgb(0 0 0 / 20%);

      @media #{$mob} {
        width: 100%;
      }
    }
  }

  @media #{$mob} {
    &.open {
      right: 0;
    }
  }

  @media #{$tab} {
    width: 100%;
  }
}

.mega-menu {
  position: fixed;
  height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  padding: 12.125em 12.5em 6.25em 12.5em;
  opacity: 0;
  visibility: hidden;
  transition: all ease .75s;

  @media #{$ipadpro} {
    padding: 10em 5em 0;
  }

  @media #{$ipad} {
    padding: 6em 3em 0;
  }

  @media #{$mob} {
    padding: 6em 1em 0;
  }


  &::before {
    content: '';
    height: 4em;
    width: 4em;
    border-radius: 50%;
    position: fixed;
    top: 5.5vh;
    right: 5.5vw;
    background: rgba($color: $blackcol, $alpha: 0.95);
    z-index: 1000;
    transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
    z-index: -1;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    opacity: 0;
    visibility: hidden;
    transition: .5s;

    @media #{$mob} {
      flex-direction: column;
    }
  }

  &__menu-links {
    // margin: 0 0 4.0625em 0;

    // @media #{$ipadpro} {
    //   margin: 0 0 2em 0;
    // }

    a {
      font-size: 5.5em;
      font-weight: 700;
      letter-spacing: -0.02em;
      color: $whitecol;
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      background-image: linear-gradient(to right, $teritary 45%, $whitecol 55%);
      background-size: 220% 100%;
      background-position: 100% 50%;
      transition: 0.5s ease-out;

      @media #{$ipadpro} {
        line-height: 1;
        font-size: 3em;
      }

      &:hover,
      &.active {
        background-position: 0% 50%;
      }
    }
  }

  &.open {
    opacity: 1;
    visibility: visible;
    transition: all ease .75s;

    &::before {
      transform: scale(80);
    }


    @media #{$mob} {
      overflow: auto;
      background: rgba($color: $blackcol, $alpha: .8);

      &::before {
        display: none;
      }
    }
  }
}

.menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  max-width: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-family: $basefont;

  @media #{$ipadpro} {
    padding: 7em 0;
    width: 100%;
  }

  &__menuitem {
    position: relative;
    padding: 0.5em 1.125em;
    margin: 0;

    &.cross {
      height: 0;
      width: 0;
      transition: .5s;
    }

    @media #{$ipadpro} {
      width: 100%;
      padding: .75em 1em .75em 1em;
      margin: 0;
      border-bottom: 1px solid rgba($color: $blackcol, $alpha: 0.1)
    }

    &--menulink {
      display: inline-block;
      font-size: 1.125em;
      letter-spacing: 0.125em;
      line-height: 1;
      color: $whitecol;
      text-transform: uppercase;
      text-decoration: none;
      transition: .5s ease;

      @media #{$ipadpro} {
        font-size: 0.95em;
        color: $blackcol;
        font-family: $basefont;
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        height: 0.125em;
        border-radius: 2.3125em;
        background: $primarycol;
        transform: scaleX(0);
        transition: transform .5s ease;

      }
    }

    &:hover,
    &.active {}

    .inner-menu {
      position: absolute;
      background: $whitecol;
      // width: 100%;
      box-shadow: 0px 14px 12px rgba(0, 0, 0, 0.3);
      // border-radius: 0.625em;
      overflow: hidden;
      top: -100%;
      left: 0;
      opacity: 0;
      visibility: hidden;
      transition: .5s;

      @media #{$ipadpro} {
        position: relative;
        opacity: 1;
        visibility: visible;
        background: transparent;
        box-shadow: none;
        height: 0;
        transition: none;
      }

      li {
        margin: 0;

        @media #{$ipadpro} {
          padding: 0;
          font-size: initial;
        }

        a {
          display: block;
          padding: 1.5625em 1.875em;
          color: $blackcol;
          background: $whitecol;
          border-bottom: 1px solid rgba($color: $blackcol, $alpha: 0.2);
          transition: .5s;
          font-size: 0.9em;

          @media #{$ipadpro} {
            border: none;
            padding-left: 1em;
            font-size: 0.75em;
          }

          &::before {
            display: none;
          }

          &:hover {
            color: $secondarycol;
            transition: .5s;
          }
        }

        &:last-child {
          @media #{$ipadpro} {
            border: none;
          }

          a {
            @media #{$ipadpro} {
              border: none;
              padding-bottom: 0.75em;
            }
          }
        }
      }

      &.openMegaMenu {
        height: unset;
        transition: none;

      }

      .menu__menuitem--menulink {
        &.active {
          color: $secondarycol  !important;
        }
      }
    }

    &.has-childMenu {
      position: relative;

      .menu__menuitem--menulink {
        &::after {
          content: '';
          position: absolute;
          background: url(../svg/drop-arrow.svg) no-repeat;
          background-size: contain;
          width: 0.375em;
          height: 0.25em;
          right: 0.25em;
          bottom: 1em;
        }
      }

      .mobileToggle {
        @media #{$ipadpro} {
          width: 3.125em;
          height: 2.125em;
          position: absolute;
          right: 60%;
          top: 0.5em;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 99;
          // @media #{$mob} {
          //   right: 0;
          // }
          &::before {
            content: "";
            display: block;
            width: 2em;
            height: 2em;
            background: linear-gradient($blackcol, $blackcol), linear-gradient($blackcol, $blackcol), transparent;
            background-position: center;
            background-size: 50% 1px, 1px 50%;
            background-repeat: no-repeat;
          }

          &.open--menu {
            &::before {
              @media (max-width: 1100px) {
                background-size: 50% 1px, 0 50%;
              }
            }

          }

        }
      }

      &:hover {
        .inner-menu {
          top: 100%;
          opacity: 1;
          visibility: visible;
          // transition: none;
        }
      }

      .inner-menu {
        .menu__menuitem {
          padding: 0;

          &:hover {
            background: $whitecol;
          }

          .menu__menuitem--menulink {
            text-transform: capitalize;
            font-weight: 400;
            font-size: 1em;
            font-family: $basefont;
            letter-spacing: -0.0625em;
          }
        }
      }
    }
  }
}

.nav-button {
  display: none;
  position: relative;
  height: 0.875em;
  width: 1.9375em;
  padding: 0;
  background: transparent;
  transition: all 250ms ease-out;
  z-index: 99;
  cursor: pointer;

  @media #{$ipadpro} {
    display: flex;
    flex-wrap: wrap;

  }

  &::before,
  &::after {
    content: '';
    width: 100%;
    height: 3px;
    position: absolute;
    // background: $secondarycol;
    background: $whitecol;
    transition: all 250ms ease-out;
    will-change: transform;
  }

  &::before {
    transform: translateY(0);
  }

  &::after {
    transform: translateY(10px);
  }

  &.active {

    &::before {
      transform: translateY(0) rotate(45deg);
      background: $primarycol;
    }

    &::after {
      transform: translateY(0) rotate(-45deg);
      background: $primarycol;
    }
  }
}