.animate {
    opacity: 0;

    &.fadein-Left {
        transform: translate(50px, 0);
    }

    &.fadein-Right {
        transform: translate(-50px, 0);
    }

    &.fadein-Up {
        transform: translate(0, 50px);
    }

    &.fadein-Down {
        transform: translate(0, -50px);
    }

    @media (max-width: 767px) {
        opacity: 1;

        &.fadein-Left {
            transform: translate(0, 0);
        }

        &.fadein-Right {
            transform: translate(0, 0);
        }

        &.fadein-Up {
            transform: translate(0, 0);
        }

        &.fadein-Down {
            transform: translate(0, 0);
        }
    }
}

@keyframes anim {
    to {
        transform: translate(0, 0);
        opacity: 1;
    }
}

.lazy-img {
    opacity: 0;
}