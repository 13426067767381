/** Generated by FG **/
@font-face {
    font-family: 'Superclarendon';
    src: url('../fonts/SuperclarendonRegular.ttf');
    font-weight: 400;
}

/** Generated by FG **/
@font-face {
    font-family: 'futura-lt-bt-light';
    src: url('../fonts/futult__.ttf');
    font-weight: 400;
}