.footer {
    padding: 5em 0 0;
    position: relative;

    @media #{$mob} {
        padding: 2em 0 0;
    }

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, #112267 0%, #263678 21.18%, #253D84 32.48%, #28458A 38.25%, #34579B 55.14%,
                #3C64A8 71.43%, #426DB3 86.73%, #4B7DC4 100%);
        bottom: 0;
        z-index: 0;

        @media #{$mob} {
            height: 100%;
        }
    }

    &__wrapper {
        position: relative;
        margin-bottom: 1.875em;
        z-index: 1;

        .col_12 {
            @media #{$mob} {
                margin: 1em 0;
            }
        }

        .footer-logo {
            width: 10.3125em;
            height: 8em;

            @media #{$mob} {
                margin: 0 auto;
            }
        }
        .invalid-feedback {
            position: relative;
            color: $whitecol;
        }
    }

    &__form {
        padding: 0 8em 0 0;

        @media #{$ipadpro} {
            padding: 0 2em 0 0;
        }

        .anchor-wrapper {
            margin: 0.5em 0 0 0;
        }
    }

    &__bottom {
        position: relative;
        padding: 1.75em 0;
        border-top: 1px solid rgba($color: $whitecol, $alpha: 0.5);

        .copy-wrapper {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            @media #{$mob} {
                justify-content: center;
            }

            p {
                font-family: $basefont;
                letter-spacing: -0.02em;
                color: $whitecol;
                font-size: 0.875em;

                @media #{$ipad} {
                    padding: 0 0 1em 0;
                    text-align: center;
                    margin: 0 auto;
                }
            }
        }

        &--privacy-wrapper {
            display: flex;
            justify-content: center;
            list-style: none;
            padding: 0;
            margin: 0;
            color: $whitecol;

            @media #{$ipad} {
                margin: 0 auto;
            }

            li {
                margin: 0 0 0 1.5625em;
                padding: 0 1.5625em 0 0;
                font-family: $basefont;
                letter-spacing: -0.02em;
                font-size: 0.875em;

                @media #{$ipad} {
                    font-size: 1em;
                    margin: 0 0 0 1em;
                    padding: 0 1em 0 0;
                }

                &:last-child {
                    padding: 0;
                }

                a {
                    color: $whitecol;
                    font-size: 1em;
                }
            }

            // @media #{$mob}{
            //     flex-wrap: wrap;
            // }
        }

        .w-uline {
            &::after {
                background: $whitecol;
            }

            &:hover {
                color: $whitecol;
            }
        }
    }

    .btn-submit-wrapper {
        position: relative;
        display: inline-block;
    }

    .btn-loader {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
    }

    button {
        margin-bottom: 0;
    }
}

.social-wrapper {
    margin-top: 3.125em;
    font-family: $basefont;

    .social-icon-wrapper {
        display: flex;
        margin: 0.875em 0 0 0;
        align-items: center;

        .socail-icon {
            margin: 0 0.5em 0 0;
            width: 2em;
            height: 2em;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: .5s ease-in-out;
            border-radius: 50%;

            &:hover {
                background: $secondarycol;
                transition: .5s ease-in-out;
            }
            svg{
                width: 1.25em;
                height: 1.25em;
            }
        }
    }

    h6 {
        font-family: $basefont;
        // letter-spacing: 0.1875em;
    }
}

.q-links-wrapper {
    .q-link-item-head {
        margin-bottom: 1.5625em;
        font-family: $basefont;

        @media #{$mob} {
            margin-bottom: 1.125em;
        }
    }

    .q-link-item {
        font-size: 1.125em;
        color: $whitecol;
        margin-bottom: 1.25em;

        @media #{$ipadpro} {
            margin-bottom: 1em;
        }

        .q-link-item-anchor {
            color: $whitecol;
            align-items: center;
        }

        .image {
            margin-right: 0.625em;
        }
    }
}

.footer-slider {
    margin-bottom: -3.5em;

    @media #{$ipadair} {
        margin-bottom: -3.2em;
    }

    @media #{$ipad} {
        margin-bottom: -4.2em;
    }

    @media #{$mob} {
        display: none;
    }
    .swiper-wrapper {
        margin: 1em;
    }
    .swiper-slide {
        .img-wrapper {
            // overflow: hidden;

            img {
                transform: scale(1);
                transition: .5s;

                &:hover {
                    transform: scale(1.1);
                    transition: .5s;
                }
            }
        }
    }
}