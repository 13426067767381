@mixin secspacing() {
    padding: 5em 0;

    @media #{$ipad} {
        padding: 3em 0;
    }

    @media #{$mob} {
        padding: 2em 0;
    }
}


@mixin transition($property: all, $duration: 0.45s, $ease: cubic-bezier(0.65, 0, .076, 1)) {
    transition: $property $duration $ease;
}


@mixin clamp {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    white-space: normal;
}