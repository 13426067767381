$primarycol: #112267;
$secondarycol: #4B7DC4;
$teritary: #AC936A;
$paracol: #111820;
$whitecol: #ffffff;
$blackcol: #000000;
$btnGradient: linear-gradient(90deg, #112267 0%, #263678 21.18%, #253D84 32.48%, #28458A 38.25%, #34579B 55.14%, #3C64A8 71.43%, #426DB3 86.73%, #4B7DC4 100%);
// other colors
$lightBlueBG: rgba(75 , 125, 196 , 0.05);
$graybg: #F6F8FC;


$graycol: #1C1C21;
$lightgray: #F9F9F9;

$basefont: 'futura-lt-bt-light', sans-serif;
$headfont: 'Superclarendon', sans-serif;
$transition: all 0.3s ease-in-out;
$bx-shadow: 0px 4px 17px rgba(55, 72, 106, 0.1);


//  Form Color
$successcol : #198754;
$dangercol : #dc3545;


// Media Sizes
$tab: '(min-width:576px)';
$tabL: '(min-width:768px)';
$ipadL: '(min-width:992px)';
$ipadXL: '(min-width:1025px)';
$desktop: '(min-width:1200px)';

$ipadproL: '(max-width:1366.99px)';
$ipadpro: '(max-width:1199.98px)';
$ipadair: '(max-width:1025.98px)';
$ipad: '(max-width:991.98px)';
$mobL: '(max-width:767.98px)';
$mob: '(max-width:575.98px)';

$space1: 0.5em;
$space2: 1em;
$space3: 1.5em;