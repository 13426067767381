h1,
.h1 {
  font-size: 4.6875em; // 75px
  font-weight: 400;
  margin: 0 0 0.5em 0;
  line-height: 1.2;
  text-transform: capitalize;
  font-family: $headfont;

  &.sm-font {
    font-size: 6.25em; // 100px

    @media #{$ipadpro} {
      font-size: 2.5em;
    }

    @media #{ $ipad} {
      font-size: 2em;
      margin: 0 0 0.5em 0;
    }
  }

  @media #{$ipadpro} {
    font-size: 2.5em;
  }

  @media #{$ipad} {
    font-size: 2.5em;
    margin: 0 0 0.5em 0;
  }
}

h2,
.h2 {
  font-size: 2.875em; // 46px
  font-weight: 400;
  margin: 0 0 0.833em 0;
  line-height: 1.2;
  text-transform: capitalize;
  font-family: $headfont;
  letter-spacing: -0.0625em;

  @media #{$ipadpro} {
    font-size: 2em;
    margin-bottom: 1.1em;
  }

  @media #{$ipad} {
    font-size: 1.75em;
  }

  @media #{$mob} {
    margin-bottom: 0.7em;
  }

  &.xl-font {
    font-size: 4.6875em; // 75px
    margin: 0 0 0.5em 0;
    line-height: 1.2;

    @media #{$ipadpro} {
      font-size: 2.5em;
    }

    @media #{$ipad} {
      margin: 0 0 0.5em 0;
    }

    @media #{$mob} {
      font-size: 2em;
      margin: 0 0 0.5em 0;
    }
  }

}

h3,
.h3 {
  font-size: 1.875em; //30px
  font-weight: 400;
  margin: 0 0 .5em 0;
  line-height: 1;
  text-transform: capitalize;
  font-family: $headfont;

  @media #{$ipadpro} {
    font-size: 1.5em;
  }

  @media #{ $ipad} {
    font-size: 1.25em;
  }

  &.lg-font {
    font-size: 3.125em; //50px
    margin: 0 0 .9em 0;

    @media #{$ipadpro} {
      font-size: 2.5em;
    }

    @media #{$ipad} {
      font-size: 2em;
    }
  }

  &.sm-font {
    font-size: 3em; //48px

    @media #{$ipadpro} {
      font-size: 1.5em;
    }

    @media #{ $ipad} {
      font-size: 1em;
    }
  }
}

h4,
.h4 {
  font-size: 1.5em; // 24px
  font-weight: 400;
  margin: 0 0 0.6em 0;
  line-height: 1.25;
  text-transform: capitalize;
  font-family: $headfont;

  @media #{$ipadpro} {
    font-size: 1.25em;
  }

  @media #{$mob} {
    font-size: 1.125em;
  }

  // &.sm-font {
  //   font-size: 2.1875em; // 35px

  //   @media #{$ipadpro} {
  //     font-size: 1.5em;
  //   }

  //   @media #{$mob} {
  //     font-size: 1.25em;
  //   }
  // }

  // &.xs-font {
  //   font-size: 2em; // 32px

  //   @media #{$ipadpro} {
  //     font-size: 1.5em;
  //   }

  //   @media #{$mob} {
  //     font-size: 1em;
  //   }
  // }


}

h5,
.h5 {
  font-size: 1.25em; // 20px
  font-weight: 400;
  margin: 0 0 0.75em 0;
  line-height: 1.3em;
  text-transform: capitalize;
  letter-spacing: normal;
  font-family: $headfont;
  margin-bottom: 0.6em;

  &.sm-font {
    font-size: 1.375em; // 22px
    font-weight: 400;
  }

  @media #{$ipadpro} {
    font-size: 1.25em;
  }

  @media #{$mob} {
    font-size: 1em;
  }
}

h6,
.h6 {
  font-size: 1.25em; // 20px
  font-weight: 400;
  margin: 0 0 0.75em 0;
  line-height: 1.3em;
  text-transform: capitalize;
  letter-spacing: normal;
  font-family: $headfont;
  margin-bottom: 0.6em;

  @media #{$ipadpro} {
    font-size: 1em;
  }

  &.sm-font {
    font-size: 1.125em; // 18px
    margin-bottom: 1.25em;

    @media #{$ipad} {
      margin-bottom: 1em;
    }
  }
}

p,
.p {
  font-size: 1.125em; // 18px
  font-weight: 400;
  margin: 0 0 1.125em 0;
  line-height: 1.3684em;
  font-family: $basefont;
  text-align: justify;

  &.lg-font {
    font-size: 1.375em; // 22px
    font-weight: 500;
  }

  &.sm-font {
    font-size: 1em;
  }

  &.xs-font {
    font-size: 0.875em; // 14px
    font-weight: 400;
  }

  @media #{$ipad} {
    font-size: 1.125em;
  }

  @media #{$ipad} {
    font-size: 1em;
  }
}

ul {
  li {
    font-family: $basefont;
  }
}