.custom-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.9375em;
}



.col-one-padding {
  @media #{$ipadL} {
    padding-left: em(63);
  }

  @media #{$desktop} {
    padding-left: em(127);
  }
}

.no-gutters {
  margin: 0;
  width: 100%;

  >[class*="col_"] {
    padding: 0;
  }
}



$grid__bp-sm: 576;
$grid__bp-md: 768;
$grid__bp-lg: 992;
$grid__bp-xl: 1200;
$grid__cols: 12;

$map-grid-props: ('': 0,
  '_sm': $grid__bp-sm,
  '_md': $grid__bp-md,
  '_lg': $grid__bp-lg,
  '_xl': $grid__bp-xl);


@mixin create-mq($breakpoint) {
  @if($breakpoint==0) {
    @content;
  }

  @else {
    @media (min-width: ($breakpoint * 1px)) {
      @content;
    }
  }
}

@mixin create-col-classes($modifier, $grid-cols, $breakpoint) {
  @include create-mq($breakpoint) {
    .col#{$modifier}_offset_0 {
      margin-left: 0;
    }

    @for $i from 1 through $grid-cols {
      .col#{$modifier}_#{$i} {
        flex: 0 0 calc(100 / ($grid-cols / $i)) * 1%;
        width: calc(100 / ($grid-cols / $i)) * 1%;
        padding: 0 0.9375em;
      }

      .col#{$modifier}_offset_#{$i} {
        margin-left: calc(100 / ($grid-cols / $i)) * 1%;
      }
    }
  }
}


@each $modifier,
$breakpoint in $map-grid-props {
  @include create-col-classes($modifier, $grid__cols, $breakpoint);
}