@import "./productCards.scss";


.generic-navigation {

    .swiper-button-next,
    .swiper-button-prev {
        position: absolute;
        width: 3.25em;
        height: 3.25em;
        top: 50%;
        border: 2px solid $primarycol;
        border-radius: 100%;
        transition: .4s;
        cursor: pointer;

        &::after {
            content: '';
        }

        &:hover {
            border: 2px solid $secondarycol;
            transition: .4s;

            svg {
                path {
                    fill: $secondarycol;
                    transition: .4s;
                }
            }
        }

        @media #{$ipad} {
            width: 2.5em;
            height: 2.5em;
        }
    }

    .swiper-button-next {
        transform: rotate(180deg);
    }
    &.related-arrows{
        
    }
}


.content-card {
    .teamCard-content-wrapper {
        padding: 1.875em 1em 1.875em 0;

        @media #{$ipad} {
            padding: 1.875em 0;

        }
    }

    .img-wrapper {
        overflow: hidden;
        border-radius: 0.9375em;
        height: auto;
        max-height: 24.8125em;

        @media #{$ipadpro} {
            height: auto;
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            -webkit-transform: rotate(5deg) scale(1.2);
            transform: rotate(5deg) scale(1.2);
            -webkit-transition: .2s ease-in-out;
            transition: .2s ease-in-out;
        }
    }

    &:hover {
        img {
            -webkit-transform: rotate(0) scale(1.05);
            transform: rotate(0) scale(1.05);
        }
    }
    .pet-tag{
        position: absolute;
        top: 1em;
        right: 1em;
        z-index: 1;
        padding: 0.625em 0.9375em;
        background: $whitecol;
        color: $primarycol;
        border-radius: 0.625em;
        &.available {
            background: #4b7dc4; 
            color: #fff;
        }
    
    }
}

.btn-submit-wrapper {
    position: relative;
    display: inline-block;
    .btn-loader {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
    }
    button {
        margin-bottom: 0;
    }
    button:disabled,
    button[disabled]{
        opacity: 0;
    }
}