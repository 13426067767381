button {
  -webkit-appearance: button;
  width: auto;
  border: 0;
  box-shadow: none;
  background: transparent;
  outline: 0;
  background: 0 0;
  border-radius: 0;
  cursor: pointer;
  font-size: 1.125em;
  padding: 0;

  &:focus {
    outline: none;
  }
}

.anchor-wrapper {
  display: flex;
  flex-wrap: wrap;

  &.center-anchor {
    justify-content: center;
  }
}

.anchor {
  color: $whitecol;
  text-decoration: none;
  transition: 0.4s;

  &:hover {
    color: $primarycol;
    transition: 0.4s;
  }
}

.uline {
  position: relative;
  text-decoration: none;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -0.125em;
    width: 100%;
    height: 1px;
    background: $secondarycol;
    transform: scaleX(1);
    transition: transform 0.5s ease;
  }

  &:hover,
  &.active {
    &::after {
      transition: transform 0.5s ease;
      transform: scaleX(0);
    }
  }
}

.w-uline {
  position: relative;
  text-decoration: none;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -0.125em;
    width: 100%;
    height: 2px;
    background: $whitecol;
    transform: scaleX(0);
    transition: transform 0.5s ease;
  }

  &:hover,
  &.active {
    color: $whitecol;
    transition: transform 0.5s ease;

    @media #{$ipadpro} {
      color: $secondarycol;
    }

    &::after {
      transition: transform 0.5s ease;
      transform: scaleX(1);
    }
  }
}

// .primary-anchor {
//   position: relative;
//   display: flex;

//   button,
//   a {
//     display: flex;
//     align-items: center;
//     color: $whitecol;
//     position: relative;
//     font-family: $basefont;
//     padding: 1.4375em 2.5em;
//     background: $primarycol;
//     border-radius: 1.375em;
//     line-height: 1;
//     transition: 0.4s;
//     z-index: 1;
//     transition: 0.4s all cubic-bezier(0.25, 0.46, 0.45, 0.94);
//   }
// }

// .arrow-anchor {
//   position: relative;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-family: $basefont;

//   span {
//     color: $primarycol;
//     font-size: 1em;
//     font-weight: 400;
//     text-transform: uppercase;
//     font-family: $basefont;
//     letter-spacing: -0.02em;
//     z-index: 5;
//     margin: 0 1.25em 0 0;

//     @media #{$mob} {
//       font-size: .95em;
//     }
//   }

//   .img-wrapper {
//     background: $whitecol;
//     width: 3.375em;
//     height: 3.375em;
//     border-radius: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     position: relative;
//     transition: .4s;

//     // &::before {
//     //   content: '';
//     //   position: absolute;
//     //   background: $btnGradient;
//     //   width: 100%;
//     //   height: 100%;
//     //   border-radius: 50%;
//     //   transition: .4s;
//     //   opacity: 0;
//     //   visibility: hidden;
//     //   z-index: 1;
//     // }

//     svg {
//       width: 0.75em;
//       transition: .4s;
//       z-index: 5;
//     }
//   }

//   &:hover {
//     .img-wrapper {
//       background: $btnGradient;
//       transition: .4s;

//       // &::before {
//       //   transition: .5s;
//       //   opacity: 1;
//       //   visibility: visible;
//       // }

//       svg {
//         transition: .5s;

//         path {
//           fill: $whitecol;
//           transition: .5s;
//         }
//       }
//     }
//   }

//   &.blue-btn {
//     span {
//       color: $primarycol;
//       transition: .5s;
//     }

//     .img-wrapper {
//       background: $primarycol;

//       svg {
//         path {
//           fill: $whitecol;
//           transition: .5s;
//         }
//       }
//     }

//     &:hover {
//       .img-wrapper {
//         background: $btnGradient;
//       }
//     }

//   }

//   &.whitebtn {
//     span {
//       color: $whitecol;
//       transition: .5s;
//     }
//   }
// }

.secondary-wrapper {
  position: relative;
  display: flex;

  button,
  a {
    color: $blackcol;
  }

  &:hover {
    span {
      color: $whitecol;
    }
  }

  span {
    color: $blackcol;
  }



  &.center-anchor {
    justify-content: center;
  }

}

.anchor-wrapper {
  &.inquire-btn {
    .arrow-anchor {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: futura-lt-bt-light, sans-serif;
      background: linear-gradient(90deg, #112267 0, #263678 21.18%, #253d84 32.48%, #28458a 38.25%, #34579b 55.14%, #3c64a8 71.43%, #426db3 86.73%, #4b7dc4 100%);
      padding: 1.5em 1.4375em 1.5em 5.375em;
      border-radius: 10.625em;
      transition: all .5s linear;
      background-size: 100%;

      &:hover {
        background-size: 250%;

        .img-wrapper {
          &::before {
            background: none;
          }
        }
      }

      span {
        color: $whitecol;
        font-size: 1.375em;
        font-weight: 400;
        text-transform: uppercase;
        font-family: $basefont;
        letter-spacing: normal;
        z-index: 5;
        margin: 0 3.375em 0 0;

        @media #{$mob} {
          font-size: .95em;
        }
      }

      .img-wrapper {
        background: none;
        width: auto;
        height: auto;
        border-radius: none;

        &::before {
          background: none;
        }
      }
    }
  }
}

.send-btn {
  background: $primarycol;
  padding: 1.25em 1.875em;
  border-radius: 0.625em;
  width: max-content;
  cursor: pointer;

  .send-btn-anchor {
    color: $whitecol;
  }
}


.primary-anchor {
  display: inline-flex;

  a,
  button {
    position: relative;
    display: flex;
    align-items: center;
    padding: 1em 0;
    margin: 0 0 1em 0;

    &::before {
      content: "";
      position: absolute;
      width: 3.25em;
      height: 3.25em;
      // border: 1px solid #d50032;
      background: $primarycol;
      border-radius: 2em;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
      transition: all .45s cubic-bezier(.65, 0, .076, 1);
    }

    .arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2.25em;
      padding: 0 1em 0 0 #{"/*rtl: 0 1em 0 0;*/"};
      z-index: 1;
      transform: #{"/*rtl: rotate(180deg);*/"};

      svg {
        path {
          fill: #fff;
          transition: .5s;
        }
      }
    }

    .text {
      margin-right: 1.25em;
      font-family: $basefont;
      color: $primarycol;
      font-weight: 400;
      font-size: 1em;
      padding: 0 1em 0 0;
      text-transform: uppercase;
      transition: all .45s cubic-bezier(.65, 0, .076, 1);
    }

    &:hover {
      &::before {
        width: 100%;
        transition: all .45s cubic-bezier(.65, 0, .076, 1);
      }

      .text {
        color: $whitecol;
        padding: 0 1em 0 2em;
        transition: all .45s cubic-bezier(.65, 0, .076, 1);
        z-index: 1;
        font-family: $basefont;
      }
    }
  }

  &.white-btn {

    a,
    button {
      &::before {
        background: $whitecol;
      }

      .text {
        color: $whitecol;
      }

      .arrow {
        svg {
          path {
            fill: $primarycol;
          }
        }
      }

      &:hover {
        .text {
          color: $primarycol;
        }
      }
    }
  }
}