.home-hero {
    height: 100vh;

    @media #{$ipadpro} {
        height: 40em;
    }

    @media #{$ipad} {
        height: 55em;
    }

    @media #{$mob} {
        height: 100vh;
    }

    &__heroSlider {
        @media #{$ipadpro} {
            height: 100%
        }
    }

    &__heroSlider {
        height: 100%;
        position: relative;

        .swiper-pagination {
            left: auto;
            right: 0;
            top: 50%;
            width: 6.25em;
            height: 7.1875em;

            @media #{$ipadpro} {
                top: 40%;
            }
            @media #{$ipad} {
                top: 45%;
            }
            @media #{$mob} {
                width: 5em;
                top: 35%;
            }

            .swiper-pagination-bullet {
                position: relative;
                height: 2.125em;
                color: $whitecol;
                background: none;
                display: flex;
                align-items: center;
                justify-content: end;
                transition: 0.5s ease-in-out;
                opacity: 1;
                transition: .5s;

                span {
                    opacity: 0;
                    transition: .5s;

                    @media #{$ipadpro} {
                        margin-right: 1em;
                    }

                    @media #{$mob} {
                        margin-right: 0;
                    }
                }

                &::before {
                    position: absolute;
                    content: '';
                    width: 0.3125em;
                    height: 0.3125em;
                    background: $whitecol;
                    left: 1em;
                    border-radius: 50%;

                    @media #{$ipadpro} {
                        left: 2em;
                    }

                    @media #{$mob} {
                        left: 1em;
                    }
                }

                &::after {
                    position: absolute;
                    content: '';
                    width: 0;
                    height: 2px;
                    background: $whitecol;
                    right: -6em;
                    transition: .5s;
                }

                &.swiper-pagination-bullet-active {
                    opacity: 1;

                    span {
                        opacity: 1;
                        transition: .5s;
                        font-family: $headfont;
                        font-weight: 400;
                        font-size: 1.375em;
                    }

                    &::before {
                        width: 0.5em;
                        height: 0.5em;
                    }

                    &::after {
                        width: 3.5em;
                        transition: .5s;
                    }
                }
            }
        }
    }

    .sliderWrapper {
        position: relative;
        height: 100%;

        @media #{$ipadpro} {
            height: 100%
        }

        .img-wrapper {
            position: relative;
            height: 100%;
            width: 100%;

            &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, rgba(0, 0, 0, .7) -12.17%, rgba(0, 0, 0, .0001) 84.04%);
            }

            @media #{$ipadpro} {
                height: 100%
            }

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;

                @media #{$ipadpro} {
                    object-fit: cover;
                }
            }
        }

    }

    .slideContent {
        position: absolute;
        top: 35%;
        padding: 0 10.25%;
        width: 60%;
        z-index: 5;

        @media #{$ipadpro} {
            width: 60%;
            padding: 0 5em;
        }

        @media #{$ipad} {
            width: 80%;
            top: 45%;
            padding: 0 3em;
        }

        @media #{$mob} {
            padding: 0 2em;
            top: 35%;
        }

        p {
            padding-right: 15em;

            @media #{$ipadpro} {
                padding-right: 2em;
            }
        }
    }

    .img-wrapper {
        position: relative;

        &::before {
            content: "";
            background: rgba(0, 0, 0, .3);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            transform: matrix(1, 0, 0, -1, 0, 0);
            z-index: 5;
        }

        .video-wrapper {
            iframe {
                width: 100vw;
                height: 62.25vw;
                min-height: 100vh;
                min-width: 177.77vh;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 0;
            }

            &::before {
                content: "";
                background: linear-gradient(180deg, rgba(0, 0, 0, .84) -22.86%, rgba(12, 13, 14, 0) 71.44%);
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                transform: matrix(1, 0, 0, -1, 0, 0);
                z-index: 1;
            }
        }
    }
}

// ---------------------   featured --------------- //

.featured {
    &__contentWrapper {
        padding-bottom: 0.75em;
    }

    .featured-image-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @media #{$ipad} {
            flex-direction: column-reverse;
            align-items: flex-start;
        }
    }

    .featured-img {
        border-radius: 15px 0px 0px 15px;
        overflow: hidden;
        height: 37.625em;
        @media #{$ipadL} {
            margin: 0 -12em 0 15em;
        }

        @media #{$ipadpro} {
            margin: 0 -12em 0 1.5em;
        }

        @media #{$ipad} {
            margin: 2em 0;
            height: 28.625em;
        }

        img {
            @media #{$ipad} {
                border-radius: 1.25em;
            }
        }
    }
}

// ---------------------   Blue Sec --------------- //

.blue-sec {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 80%;
        background: $secondarycol;
        bottom: 0;
        border-top-right-radius: 6em;
        border-top-left-radius: 6em;
    }

    .blue-slider-wrapper {
        position: relative;
        padding: 0 5em;

        @media #{$ipad} {
            padding: 0 5em;
        }

        .blue-sec-slider {
            position: relative;

            .swiper-slide {
                text-align: center;

                @media #{$ipadpro} {

                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                .img-wrapper {
                    width: 11.0625em;
                    height: 12.25em;
                    margin: 0 auto;
                    overflow: hidden;
                    border-radius: 0.9375em;

                    @media #{$ipadpro} {
                        width: 100%;
                        height: auto;
                        max-height: 11.43em;
                    }

                    img {
                        width: 100%;
                        height: auto;
                        border-radius: 1em;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        -webkit-transform: rotate(5deg) scale(1.2);
                        transform: rotate(5deg) scale(1.2);
                        -webkit-transition: .2s ease-in-out;
                        transition: .2s ease-in-out;
                    }
                }

                .content-wrap {
                    padding: 1.25em;

                    h5 {
                        margin: 0;
                    }
                }

                &:hover {
                    img {
                        -webkit-transform: rotate(0) scale(1.05);
                        transform: rotate(0) scale(1.05);
                    }
                }
            }
        }

        .swiper-navigation-wrapper {

            .swiper-button-next,
            .swiper-button-prev {
                position: absolute;
                width: 3.25em;
                height: 3.25em;
                top: 40%;
                border: 2px solid $whitecol;
                border-radius: 100%;
                transition: .4s;

                &::after {
                    content: '';
                }

                &:hover {
                    border: 2px solid $primarycol;
                    transition: .4s;

                    svg {
                        path {
                            fill: $primarycol;
                            transition: .4s;
                        }
                    }
                }

                @media #{$ipad} {
                    width: 2.5em;
                    height: 2.5em;
                }
            }

            .swiper-button-next {
                transform: rotate(180deg);
            }
        }
    }

}

// ---------------------   Breed --------------- //

.breed {
    @media #{$desktop} {
        padding: 6.25em 0 2em 0;
    }

    &__btn {
        justify-content: flex-end;

        @media #{$mob} {
            justify-content: flex-start;
        }
        .primary-anchor{
            height: max-content;
        }
    }
}

// ---------------------   Thumb Slider --------------- //

.thumbSlider {
    position: relative;

    @media #{$ipad} {
        height: 25em;
    }

    @media #{$ipad} {
        height: 100%;
    }

    .thumbSlider-Wrapper {
        position: relative;

        @media #{$desktop} {
            max-height: 39.8125em;
        }

        @media #{$ipad} {
            height: 100%
        }

        @media #{$mob} {
            height: auto
        }

        .cardSliderConatiner {
            position: absolute;
            bottom: 0;
            z-index: 2;
            height: 100%;

            @media #{$mob} {
                padding: 0;
            }
        }
    }

    .cardSlider-Wrapper {
        position: relative;
        height: 100%;

        .thumb-pad {
            padding: 0;

            @media #{$ipadpro} {
                padding: 0 1em;
            }

            @media #{$ipad} {
                padding: 0 2em;
            }

            @media #{$mob} {
                padding: 0 5em;
            }
        }
    }

    .card-slider {
        position: relative;
        height: 100%;

        .slider-card {
            padding: 2.5em;
            background: rgba(0, 0, 0, 0.6);
            transform: translate(0, 66%);
            transition: .5s;
            cursor: pointer;

            @media #{$ipadpro} {
                transform: translate(0, 70%);
            }

            @media #{$ipad} {
                padding: 1.5em;
            }
        }

        .swiper-slide {
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            border-right: 1px solid rgba(255, 255, 255, 0.3);
            border-left: 1px solid rgba(255, 255, 255, 0.3);

            &.swiper-slide-thumb-active {
                .slider-card {
                    transform: translate(0, 0);
                    transition: .5s;
                }
            }
        }

    }

    .thumb-navigation-wrapper {

        .swiper-button-next,
        .swiper-button-prev {
            position: absolute;
            width: 3.25em;
            height: 3.25em;
            top: auto;
            bottom: 5%;
            border: 2px solid $whitecol;
            border-radius: 100%;
            transition: .25s;

            &::after {
                content: '';
            }

            &:hover {
                border: 2px solid $blackcol;
                transition: .4s;

                svg {
                    path {
                        fill: $blackcol;
                        transition: .4s;
                    }
                }
            }

            @media #{$ipad} {
                width: 2.5em;
                height: 2.5em;
            }
        }

        .swiper-button-next {
            transform: rotate(180deg);
        }
    }

    .thumb-img-slider {
        @media #{$ipad} {
            height: 100%;
        }

        .swiper-wrapper {
            max-height: 40.17em;
            .swiper-slide {
                height: auto;
            }
        }

        .img-wrapper {
            height: 100%;
            width: 100%;

            img {
                object-position: center;
            }
        }

    }
}

// ---------------------   Testimonials  --------------- //


.testimonials {
    .testimonials-slider {
        position: relative;

        .swiper-slide {
            // margin: 0 0.9375em;
        }

        .swiper-wrapper {
            margin: 8.125em 0 3.75em 0;

            @media #{$mob} {
                margin: 8.125em 0 1.75em 0;
            }
        }
    }

    .testimonial-card {
        padding: 0 4.5em;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 0.75em rgba(17, 34, 103, .1);
        border-radius: 0.75em;
        margin: 0 0.625em;

        @media #{$ipad} {
            margin: 0 2em;
        }

        @media #{$mob} {
            margin: 0 1em;
            padding: 0 2.5em;
        }

        .img-wrapper {
            margin: -4.375em 0 0 0;

            img {
                border-radius: 0.625em;
            }
        }

        .rating {
            margin: 1.75em 0;
        }

        .quote {
            position: relative;

            &::before,
            &::after {
                content: '';
                position: absolute;
                width: 2.5em;
                height: 2.25em;
                background: url('../svg/quote.svg') no-repeat;
                background-size: cover;
                transform: #{"/*rtl: scaleX(-100%)*/"};
                @media #{$ipad} {
                    width: 1.5em;
                    height: 1.25em;
                    background-size: contain;
                }
            }

            &::before {
                top: -1.15em;
                left: -2.5em;

                @media #{$ipad} {
                    top: -0.5em;
                }

                @media #{$mob} {
                    left: -1.5em;
                }
            }

            &::after {
                bottom: -1.15em;
                right: -2.5em;
                transform: rotate(180deg) #{"/*rtl: scaleX(-100%) rotate(180deg)*/"};

                @media #{$ipad} {
                    bottom: -0.5em;
                }

                @media #{$mob} {
                    right: -1.5em;
                }
            }
        }
    }


}

.testimonial-navigation-wrapper {
    position: relative;
    width: 10em;
    height: 5em;
    display: flex;
    align-items: center;

    @media #{$ipad} {
        width: 8em;
    }

    .swiper-button-next,
    .swiper-button-prev {
        position: absolute;
        width: 3.25em;
        height: 3.25em;
        bottom: 0;
        border: 2px solid $primarycol;
        border-radius: 100%;
        transition: .4s;

        &::after {
            content: '';
        }

        &:hover {
            border: 2px solid $secondarycol;
            transition: .4s;

            svg {
                path {
                    fill: $secondarycol;
                    transition: .4s;
                }
            }
        }

        @media #{$ipad} {
            width: 2.5em;
            height: 2.5em;
        }
    }

    .swiper-button-next {
        transform: rotate(180deg);
    }
}