.header {
    padding: 1.875em 0;
    position: fixed;
    width: 100%;
    z-index: 11;
    transition: .4s;

    &.sticky {
        padding: 1em 0;
        transition: .4s;
        background: $primarycol;
        z-index: 11;

        @media #{$ipad} {
            padding: 1.75em 0;
        }

        @media #{$mob} {
            padding: 1.25em 0;
        }

        .brand-logo {
            width: 9.625em;
            height: auto;
            transition: .5s;

            @media #{$mob} {
                width: 5.25em;
            }
        }

        .search-languege-wrapper {
            @media #{$ipadpro} {
                top: 3.25em;
                transition: .3s;
            }

            @media #{$ipad} {
                top: 4em;
                transition: .3s;
            }

            @media #{$mob} {
                top: 2em;
            }

            .search-btn {
                position: relative;

                // &:hover {
                //     &::after {
                //         transition: transform .5s ease;
                //         transform: scaleX(1);
                //     }

                //     span {
                //         color: $whitecol;
                //     }

                //     .search-icon {
                //         svg {
                //             g {
                //                 path {
                //                     stroke: $whitecol;
                //                     transition: .5s;
                //                 }
                //             }
                //         }
                //     }
                // }

                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: -0.125em;
                    width: 100%;
                    height: 2px;
                    background: #fff;
                    transform: scaleX(0);
                    transition: transform .5s ease;
                }
            }

            .language-wrapper {
                span {
                    position: relative;

                    &:hover {
                        color: $whitecol;

                        &::after {
                            width: 100%;
                            transition: transform .5s ease;
                            transform: scaleX(1);
                        }
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        bottom: -0.125em;
                        width: 0;
                        height: 2px;
                        background: #fff;
                        transform: scaleX(0);
                        transition: transform .5s ease;
                    }
                }
            }
        }

        .menu__menuitem.has-childMenu:hover .inner-menu {
            @media #{$desktop} {
                top: 5.2em;
            }

        }
    }

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .brand-logo {
            width: 12.625em;
            height: auto;
            transition: .5s;
            z-index: 12;

            @media #{$ipad} {
                width: 12em;
                height: auto;
            }

            @media #{$mob} {
                width: 7em;
            }
        }
    }
}


.search-box {
    position: fixed;
    top: -125%;
    width: 100%;
    left: 0;
    transition: 1s;

    &.open {
        top: 0;
        transition: 1s;

        &::before,
        &::after {
            content: '';
        }
    }

    .field-wrapper {
        width: 100%;

        .container {
            position: relative;

            &::before {
                content: "";
                width: 100%;
                height: 100vh;
                position: absolute;
                top: 0;
                left: 0;
                background: $secondarycol;
                mix-blend-mode: normal;
                opacity: 1;
            }
        }
    }
}

.search {

    &::before,
    &::after {
        width: 100%;
        height: 3px;
        position: absolute;
        // background: $secondarycol;
        background: $whitecol;
        transition: all 250ms ease-out;
        will-change: transform;
    }

    &::before {
        transform: translateY(0);
    }

    &::after {
        transform: translateY(10px);
    }

    &.active {

        &::before {
            transform: translateY(0) rotate(45deg);
            background: $primarycol;
        }

        &::after {
            transform: translateY(0) rotate(-45deg);
            background: $primarycol;
        }
    }
}

.search-box-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex: 1 0 100%;
    height: 100vh;
    margin: 0 auto;

    .form-group {
        position: relative;

        .input-group {
            position: relative;
            cursor: text;
            transition: all .22s ease-out;
            z-index: 1;
            display: flex;
            // border-bottom: 1px solid $whitecol;
            padding: 0;

            .form-control {
                width: 6em;
                background: 0 0;
                border: 0;
                left: 0;
                outline: 0;
                padding: 0 0 0 0.75em;
                margin: 0;
                color: $whitecol;
                font-size: 1em;
                margin-bottom: 0;
                line-height: 1.28;
                border-radius: 0;
                font-family: $basefont;

                &::placeholder {
                    color: $whitecol;

                    @media #{$ipadpro} {
                        color: $blackcol;
                    }
                }

                @media #{$ipadpro} {
                    color: $blackcol;
                }
            }


        }

        svg {
            @media #{$ipadpro} {
                g {
                    path {
                        stroke: $blackcol;
                    }
                }
            }
        }
    }

    .search-form {
        padding: 0;
        width: 100%;

        form {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            border: none;

            .search_form-field {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                position: relative;
                margin-bottom: 2.875em;

                .seach-img-wrapper {
                    width: 2.5em;
                    height: 2.5em;
                    display: flex;
                    flex-wrap: wrap;
                    pointer-events: none;

                    @media #{$mob} {
                        width: 1.5em;
                        height: 1.5em;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                input {
                    font-size: 3.125em;
                    font-family: $headfont;
                    width: calc(100% - 10.06em);
                    flex: 1;
                    letter-spacing: -.02em;
                    text-transform: capitalize;
                    color: $whitecol;
                    border: none;
                    line-height: .54em;
                    padding: 0 1.3125em 0;
                    background-color: transparent;

                    @media #{$mob} {
                        font-size: 1.25em;
                    }

                    &::placeholder {
                        opacity: 0.6;
                    }
                }

                &::after {
                    content: "";
                    width: 100%;
                    height: 2px;
                    background: rgba(255, 255, 255, 0.5);
                    position: absolute;
                    bottom: -3.125em;
                    left: 0;
                }

                .primary-anchor {
                    a, button {
                        padding: 0;
                        margin: 0;
                    }

                }
            }
        }
    }
}

.search-box-form-wrapper {
    margin-left: 2.625em;

    .search-languege-wrapper {
        display: flex;

        @media #{$ipadpro} {
            position: absolute;
            top: 5em;
            right: 8em;
            transition: .3s;
        }

        @media #{$ipad} {
            top: 4.5em;
        }

        @media #{$mob} {
            top: 3em;
            right: 4em;
        }

        .search-btn {
            display: flex;
            align-items: center;
            margin-right: 3.75em;
            z-index: 12;
            cursor: pointer;

            @media #{$mob} {
                margin-right: 1em;
            }

            .cross-icon {
                width: 0.90625em;
                height: 0.90625em;

                img {
                    width: 0.90625em;
                    height: 0.90625em;

                    @media #{$ipadpro} {
                        width: 1.5em;
                        height: 1.5em;
                    }
                }
            }

            .search-icon {
                margin-right: 0.9375em;
                display: block;
                width: 0.90625em;
                height: 0.90625em;

                svg {
                    width: 0.90625em;
                    height: 0.90625em;
                }

                transition: $transition;

                &:hover {
                    svg {
                        path {
                            stroke: #4b7dc4;
                        }
                    }
                }
            }

            .cross-icon {
                display: none;
            }

            &.open {
                // margin-right: 0;

                @media #{$ipadpro} {
                    position: absolute;
                    right: -9em;
                    top: -1em;

                }

                @media #{$mob} {
                    right: -4em;
                    top: -1em;
                }

                .search-icon {
                    display: none;
                }

                .cross-icon {
                    width: 3.125em;
                    height: 3.125em;
                    background: rgba(255, 255, 255, 0.25);
                    margin: 0;
                    display: flex;
                    border-radius: 50%;
                    align-items: center;
                    justify-content: center;
                }

                span {
                    visibility: hidden;
                }
            }

            span {
                display: inline-block;
                font-size: 1.125em;
                letter-spacing: .125em;
                line-height: 1;
                color: $whitecol;
                text-transform: uppercase;
                text-decoration: none;
                // transition: .5s;

                @media #{$mob} {
                    display: none;
                }

            }

            // &:hover {
            //     .search-icon {
            //         svg {
            //             g {
            //                 path {
            //                     stroke: $primarycol;
            //                     transition: .5s;
            //                 }
            //             }
            //         }
            //     }

            //     span {
            //         color: $primarycol;
            //         transition: .5s;
            //     }
            // }
        }

        .language-wrapper {
            span {
                position: relative;
                margin-right: 0.45em;
                display: inline-block;
                font-size: 1.125em;
                letter-spacing: .125em;
                line-height: 1;
                color: $whitecol;
                text-transform: uppercase;
                text-decoration: none;
                transition: .5s ease;

                &::after {
                    content: '';
                    position: absolute;
                    // background: url(../svg/drop-arrow.svg) no-repeat;
                    background-size: contain;
                    width: 0.375em;
                    height: 0.25em;
                    right: 0;
                    bottom: 0.2em;
                }

                &:hover {
                    color: $secondarycol;
                    transition: .5s;
                }
            }
        }
    }
}

.language-wrapper {
    position: relative;

    &:hover,
    &.open {
        .inner-menu {
            top: 100%;
            opacity: 1;
            visibility: visible;
            transition: .5s;
        }
    }

    .inner-menu {
        position: absolute;
        background: #fff;
        box-shadow: 0 14px 12px rgba(0, 0, 0, .3);
        overflow: hidden;
        top: -100%;
        left: 0;
        opacity: 0;
        visibility: hidden;
        transition: .5s;

        li {
            padding: 1em;
            margin: 0;
            border-bottom: 1px solid $blackcol;
        }
    }
}